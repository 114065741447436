import './bootstrap';

$(document).on('click', '.js-remove-item', function (e){
    e.preventDefault()

    let itemId = $(this).attr('data-id')
    let itemFormId = $(this).attr('data-form-id')
    let form = $('#' + itemFormId)

    let text = $(this).attr('data-remove-text')
    let answer = confirm(text)

    if (answer) {
        if($(form).length !== 0){
            $(form).submit()
        }
    } else {
        e.preventDefault()
    }
})
